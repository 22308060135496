import React from 'react';
import { Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from '@emotion/styled';

import { getTheme } from '../utility/theme';
import Layout from '../components/Layout';
import { COLOR } from '../constants';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

const Row = styled.div`
  margin-bottom: 1rem;
`;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default function Contact() {
  const [state, setState] = React.useState({});

  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...state,
      }),
    })
      .then(a => alert('Thank you!'))
      .catch(error => alert(error));
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>Contact</h1>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <Row>
            <TextField label="Name" name="name" type="text" onChange={handleChange} fullWidth required></TextField>
          </Row>
          <Row>
            <TextField label="Email" name="email" type="email" onChange={handleChange} fullWidth required></TextField>
          </Row>
          <Row>
            <TextField
              label="Message"
              type="text"
              onChange={handleChange}
              name="message"
              multiline
              fullWidth
              rows="6"
              required
            ></TextField>
          </Row>
          <p>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </p>
        </form>
      </Layout>
    </ThemeProvider>
  );
}
